import React from 'react';

const exampleVideos = [
    {
      title: "How to Harness Abundant, Clean Energy for 10 Billion People",
      pdfLink: "/ted-talk1.pdf",
      link: "https://www.youtube.com/embed/bwEIqjU2qgk?rel=0"
    },
    {
      title: "Khan Academy announces GPT-4 powered learning guide",
      pdfLink: "/khan-academy.pdf",
      link: "https://www.youtube.com/embed/yEgHrxvLsz0?rel=0"
    },
    {
      title: "I Tried 200 AI Tools, These are the Best",
      pdfLink: "/converted.pdf",
      link: "https://www.youtube.com/embed/gpP_YEv_9jA?rel=0"
    }
  ];
  
  const ExamplesSection = () => {
    return (
      <section className="w-full max-w-7xl mx-auto px-4 mt-10">
        <h2 className="text-3xl font-bold text-dark-blue text-center mb-10">Example Outputs</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {exampleVideos.map((video, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="relative" style={{ paddingBottom: '75%' }}>
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src={video.link}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">{video.title}</h3>
                <a
                  href={video.pdfLink}
                  className="block w-full py-2 px-4 bg-light-blue text-white font-bold tracking-wide rounded-md text-center"
                  download
                >
                  Download PDF
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  };
  
  export default ExamplesSection;
  